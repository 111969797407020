<template>
  <div class="allcontens">
    <div class="content">
      <span class="smsphone">验证码发送至您银行卡所绑定手机号</span>
      <div class="smsbt">
        <van-password-input :value="verCode" :mask="false" :focused="showKeyboard" @focus="showKeyboard = true" />
        <van-number-keyboard v-model="numberKeyboard" :show="showKeyboard" @blur="showKeyboard = false" />
      </div>
      <div>
        <span style="color:#FFAA32" class="smstime" v-if="second > 0" id="getcode">已发送(<span id="J_second">{{ second }}</span>)</span>
        <span class="btsend" id="getcodeagain" v-else @click="send()">重新发送</span>
      </div>
      <div class="row justify-center">
        <van-button round type="info" size="small" class="margin-top-50 next-btn" @click="submit">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { bindBankCard, confirmBindBankCard } from '@/api/sign'
  import storage from '@/utils/storage'
  export default {
    data() {
      return {
        second: 120,
        constSecond: 120,
        verCode: '',
        showKeyboard: false,
        numberKeyboard: '',
        checked: true,
        applyOrderNo: ''
      }
    },
    created() {
      this.bodyNo = storage.getItem('bodyNo')
      this.applyOrderNo = this.$route.query.applyOrderNo
      this.time()
    },
    watch: {
      numberKeyboard: {
        handler(nVal) {
          this.verCode = nVal
        }
      }
    },
    methods: {
      async send() {
        const values = storage.getItem('bankInfo')
        const params = {
          bodyNo: this.bodyNo,
          bankCardNo: values.bankId,
          bankCode: values.bankCode,
          bankCardHolder: values.name,
          cardCheck: 6
        }
        const res = await bindBankCard(params)
        res.success && this.$router.push({ path: '/sign/bank_cardverification' })
        if (!res.success) {
          this.$toast.fail(res.respMag);
          return
        }
        storage.setItem(`${storage.insuranceNo}`, res.content.applyOrderNo)
        this.time()
      },
      time() {
        const _this = this
        const storageSecond = storage.getItem('bandSecond')
        if (storageSecond === 0) {
          this.second = 0
          return
        }
        _this.second = storage.getItem('bandSecond') || _this.constSecond
        _this.timer = setInterval(function() {
          _this.second = _this.second - 1
          if (_this.second > 0) {
            storage.setItem('bandSecond', _this.second)
          } else {
            storage.setItem('bandSecond', 0)
            clearInterval(_this.timer);
          }
        }, 1000)
      },
      async submit() {
        if (!this.checked) {
          this.$toast.fail('请勾选同意 扣款授权协议！')
          return
        }
        if (!this.verCode || this.verCode.length !== 6) {
          this.$toast.fail('请填写6位数的验证码')
          return
        }
        const params = {
          bodyNo: this.bodyNo,
          applyOrderNo: storage.getItem(`${storage.insuranceNo}`) || this.applyOrderNo,
          verCode: this.verCode,
          invoiceAddress: ''
        }
        const res = await confirmBindBankCard(params)
        if(!res.success) {
          this.$toast.fail(res.respMag)
          return
        }
        storage.clearItem('bandSecond')

        this.$router.push({ path: '/sign/account' })
      }
    }
  }
</script>
<style scoped>
  .smsbt {
    margin-top: 0.5rem;
    padding-bottom: 0.2rem;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .smsbt .van-password-input {
    width: 100%;
  }

  .smsphone {
    font-size: 0.4rem;
    font-weight: 500;
    color: #666;
    line-height: 0.8rem;
    letter-spacing: 0.02rem;
  }

  .smsinput {
    border: 0;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    padding-left: 0.4rem;
    box-sizing: border-box;
    width: 3rem;
    font-size: 0.3rem;
  }

  .smstime {
    font-size: 0.44rem;
    line-height: 0.5rem;
  }

  .btsend {
    font-size: 0.4rem;
    line-height: 0.5rem;
    color: #0084FE;
  }

  .smsnext {
    margin: 3rem auto;
  }

  .checkbox {
    width: 0.3rem;
    height: 0.3rem;
  }

  .sps {
    font-size: 0.4rem;
    line-height: 0.4rem;
    margin-left: 0.1rem;
    display: inline-block;
  }

  .checks {
    margin-top: 0.6rem;
    display: flex;
    font-size: 0.4rem;
    align-items: center;
  }

  .tel {
    width: calc((100% - 1.8rem) / 6);
    border: none;
    outline: none;
    border-radius: 0;
    border: 0.02rem solid #ccc;
    margin-right: 0.2rem;
    height: 1rem;
    font-size: 0.6rem;
    text-align: center;
    color: #999;
  }

  .tels {
    width: calc((100% - 1.8rem) / 6);
    border: none;
    outline: none;
    border-radius: 0;
    border: 0.02rem solid red;
    margin-right: 0.2rem;
    height: 1rem;
    font-size: 0.6rem;
    text-align: center;
    color: #999;
  }

  .sms_input {
    position: absolute;
    width: 100%;
    /* background: red; */
    left: 0px;
    top: 0px;
    opacity: 0;
    caret-color: #fff;
    /* letter-spacing: 0.5rem; */
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom: 0.02rem solid #ccc;
    margin-right: 0.2rem;
    height: 1rem;
    font-size: 0.1rem;
  }
</style>